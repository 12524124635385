

















































































































import api from "@/api/index"; //ABP API接口
import { Component, Vue, Watch } from "vue-property-decorator";

import { CityDto, ProvinceDto, SysSettingTaxeDto } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView.vue";
import EditSysSettingTaxe from "@/views/admin/sysSettingTaxe/edit.vue";

@Component({
  name: "SysSettingTaxeList",
  components: {
    EditSysSettingTaxe,
    PagedTableView,
  },
})
export default class SysSettingTaxeList extends Vue {
  queryForm = {
    provinceId: undefined,
    cityId: undefined,
  };
  editId = 0;
  provinceList: ProvinceDto[] | undefined = [];
  citiesList: CityDto[] | undefined = [];

  // 获取表数据
  fetchData(params: any) {
    return api.sysSettingTaxe.getAll(params);
  }

  created() {
    this.getProvinceList();
  }

  async getProvinceList() {
    await api.province.getAll({ maxResultCount: 65535 }).then((res) => {
      this.provinceList = res!.items;
    });
  }

  @Watch("provinceId")
  getCitiesList() {
    if (this.queryForm.provinceId != null) {
      console.log("provinceId=" + this.queryForm.provinceId);
      api.city
        .getAllList({
          provinceId: this.queryForm!.provinceId,
        })
        .then((res) => {
          // console.log("citiesList=" + this.citiesList);
          this.citiesList = res!;
        });
    }
  }
  formatValue(item: any) {
    let value = item * 100;
    return value;
  }
  // 新建
  handleCreate() {
    this.editId = 0;
    (this.$refs.editForm as any).show = true;
  }

  // 编辑
  handleEdit(index: number, row: SysSettingTaxeDto) {
    console.log("id=" + row!.id);
    this.editId = row!.id!;
    (this.$refs.editForm as any).show = true;
    (this.$refs.editForm as any).form = row;
  }

  handleIsDefault(index: number, row: SysSettingTaxeDto) {
    this.$confirm("你确定设置为默认吗?", "提示").then(async () => {
      await api.sysSettingTaxe
        .setDefault({
          body: row,
        })
        .then(() => {
          this.$message.success("设置默认成功");
        });
    });
  }

  // 删除
  async handleDelete(index: number, row: SysSettingTaxeDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.sysSettingTaxe
        .delete({
          id: row.id,
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
    });
  }
}
