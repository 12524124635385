






































































































































































































import api from "@/api/index"; //ABP API接口
import { Vue, Component } from "vue-property-decorator";
import PagedTableView from "@/components/product/PagedTableView.vue";
import {
  ProductCategoryCreateOrUpdateDto,
  ProductCategoryDto,
} from "@/api/appService";
import { createNgTree, INgNode } from "@/utils/tree";

@Component({
  name: "ClassificationList",
  components: {
    PagedTableView,
  },
})
export default class ClassificationList extends Vue {
  queryForm = {
    name: "",
  };

  id = 0;
  isCreate = true;
  isShow = false;
  form: ProductCategoryCreateOrUpdateDto = {
    id: 0,
    parentId: 0,
    name: "",
    nameFr: "",
    description: "",
    displayOrder: undefined,
  };

  categoryList = [] as ProductCategoryDto[];
  categoryTree: INgNode<ProductCategoryDto>[] = [];

  // 获取表数据
  fetchData(params: any) {
    console.log("test:" + params);
    return api.productCategory.getAll(params);
  }

  fetchDataList(data: any) {
    this.categoryList = data.filter(
      (item: ProductCategoryDto) => item.level !== 3
    );
    let defaultData = [
      {
        children: undefined,
        id: 0,
        key: "",
        label: "一级分类",
        parentNode: undefined,
        isLeaf: true,
        hidden: false,
        expanded: false,
        disabled: false,
        data: {
          id: 0,
          fullName: "一级分类",
        },
      },
    ] as INgNode<ProductCategoryDto>[];
    this.categoryTree = defaultData.concat(
      createNgTree(
        data.filter((item: ProductCategoryDto) => item.level !== 3),
        "parentId",
        "id",
        null,
        "children",
        null,
        false,
        "id",
        "fullName"
      )
    );
    console.log(this.categoryTree);
  }

  cascaderClick(value: any) {
    this.form.parentId = value.id;
    let _this = this as any;
    _this.$refs.cascader.checkedValue = value.id;
    _this.$refs.cascader.computePresentText();
    _this.$refs.cascader.toggleDropDownVisible(false);
  }

  // 新建
  handleCreate() {
    // this.$router.push({
    //   name: "BrandCreate"
    // });
    this.form = {} as ProductCategoryCreateOrUpdateDto;
    this.form.parentId = 0;
    this.isCreate = true;
    this.isShow = true;
  }

  handleChildrenCreate(index: number, row: ProductCategoryDto) {
    this.form = {} as ProductCategoryCreateOrUpdateDto;
    this.form.parentId = row.id;
    this.isCreate = true;
    this.isShow = true;
  }

  // 编辑
  handleEdit(index: number, row: ProductCategoryDto) {
    this.id = row.id!;
    // this.$router.push({
    //   name: "BrandEdit",
    //   params: { id: row.id! + "" },
    // });
    this.isCreate = false;
    let _this = this as any;
    api.productCategory.get({ id: this.id }).then((res: any) => {
      if (res) {
        _this.form = { ...res };
        if (res.parentId === null) {
          _this.form.parentId = 0;
        }
      }
    });
    this.isShow = true;
  }

  // 删除
  async handleDelete(index: number, row: ProductCategoryDto) {
    this.$confirm(
      (this as any).$l.getLanguageText("basic.disableTip"),
      (this as any).$l.getLanguageText("basic.tip")
    ).then(async () => {
      await api.productCategory
        .delete({
          id: row.id,
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: (this as any).$l.getLanguageText("basic.deleteSuccess"),
          });
        });
    });
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        console.log(this.form);
        if (this.form.parentId === 0) {
          this.form.parentId = undefined;
        }
        if (this.form.id) {
          await api.productCategory.update({ body: { ...this.form } });
          this.isShow = false;
          this.$message.success(
            (this as any).$l.getLanguageText("basic.updateSuccess")
          );
        } else {
          await api.productCategory.create({ body: { ...this.form } });
          this.isShow = false;
          this.$message.success(
            (this as any).$l.getLanguageText("basic.createSuccess")
          );
        }
      }
    });
  }

  roleRule = {
    name: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "commodityClassification.tips.name"
        ),
        trigger: "blur",
      },
    ],
    nameFr: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "commodityClassification.tips.nameFr"
        ),
        trigger: "blur",
      },
    ],
    parentId: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "commodityClassification.tips.classification"
        ),
        trigger: "blur",
      },
    ],
  };
}
