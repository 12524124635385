





























































































































































































































































import api from "@/api/index"; //ABP API接口
import { Component, Vue, Watch } from "vue-property-decorator";
import PagedTableView from "@/components/PagedTableView.vue";
import {
  ProductParamInputType,
  ProductSpecParamCreateOrUpdateDto,
  ProductSpecParamDto,
  SelectListItem,
} from "@/api/appService";

@Component({
  name: "SpecificationsList",
  components: {
    PagedTableView,
  },
})
export default class SpecificationsList extends Vue {
  queryForm = {
    name: "",
  };

  @Watch("form.inputType")
  onValueChange(newVal: string, oldVal: string) {
    if (newVal === "ManualInput") {
      this.inputList = [];
    }
  }

  productParamInputTypeList: SelectListItem[] = [];

  id = 0;
  isCreate = true;
  isShow = false;
  form: ProductSpecParamCreateOrUpdateDto = {
    id: 0,
    name: "",
    nameFr: "",
    description: "",
    inputList: "",
    inputType: undefined,
  };
  inputList = [] as any[];

  created() {
    this.fetchProductParamInputTypeList();
  }

  // 获取表数据
  fetchData(params: any) {
    console.log("test:" + params);
    return api.productSpecParam.getAll(params);
  }

  async fetchProductParamInputTypeList() {
    let _this = this as any;
    await api.enumService
      .getValues({ typeName: "ProductParamInputType" })
      .then((res) => {
        _this.productParamInputTypeList = res;
      });
  }

  getProductParamInputTypeText(value: string) {
    let text = "";
    for (let i in this.productParamInputTypeList) {
      if (this.productParamInputTypeList[i].value === value) {
        text = this.productParamInputTypeList[i].text!;
      }
    }
    return text;
  }

  deleteRow(index: number) {
    this.inputList.splice(index, 1);
  }

  addRow() {
    this.inputList.push({
      parameterValue: "",
      parameterValueFr: "",
      displayOrder: undefined,
    });
  }

  compare(property: any) {
    return function (a: any, b: any) {
      let value1 = a[property];
      let value2 = b[property];
      return value1 - value2;
    };
  }

  getInputListText(inputList: string) {
    try {
      JSON.parse(inputList);
      let input = JSON.parse(inputList).map(
        (item: any) =>
          item.parameterValue +
          (item.parameterValueFr ? `(${item.parameterValueFr})` : "")
      );
      return input.join(",");
    } catch (e: any) {
      return inputList;
    }
  }

  // 新建
  handleCreate() {
    // this.$router.push({
    //   name: "BrandCreate"
    // });
    this.form = {} as ProductSpecParamCreateOrUpdateDto;
    this.inputList = [];
    this.isCreate = true;
    this.isShow = true;
  }

  // 编辑
  handleEdit(index: number, row: ProductSpecParamDto) {
    this.id = row.id!;
    // this.$router.push({
    //   name: "BrandEdit",
    //   params: { id: row.id! + "" },
    // });
    this.isCreate = false;
    let _this = this as any;
    api.productSpecParam.get({ id: this.id }).then((res: any) => {
      if (res) {
        this.form = { ...res };
        _this.inputList = [];
        if (res.inputList) {
          _this.inputList = JSON.parse(res.inputList);
        }
      }
    });
    this.isShow = true;
  }

  // 删除
  async handleDelete(index: number, row: ProductSpecParamDto) {
    this.$confirm(
      (this as any).$l.getLanguageText("basic.disableTip"),
      (this as any).$l.getLanguageText("basic.tip")
    ).then(async () => {
      await api.productSpecParam
        .delete({
          id: row.id,
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: (this as any).$l.getLanguageText("basic.deleteSuccess"),
          });
        });
    });
  }

  async save() {
    let _this = this as any;
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.form.inputType === ProductParamInputType.DropDownOptions) {
          if (_this.inputList && _this.inputList.length > 0) {
            let isValid = true;
            _this.inputList.some((item: any) => {
              if (!item.parameterValue) {
                isValid = false;
                this.$message.error(
                  (this as any).$l.getLanguageText(
                    "commoditySpecifications.tips.inputList.parameterValue"
                  )
                );
                return true;
              }
              if (!item.parameterValueFr) {
                isValid = false;
                this.$message.error(
                  (this as any).$l.getLanguageText(
                    "commoditySpecifications.tips.inputList.parameterValueFr"
                  )
                );
                return true;
              }
            });
            if (!isValid) {
              return false;
            }
          } else {
            this.$message.error(
              (this as any).$l.getLanguageText(
                "commoditySpecifications.tips.inputList"
              )
            );
            return false;
          }
        }
        console.log(this.form);
        _this.inputList.sort(_this.compare("displayOrder"));
        _this.form.inputList = JSON.stringify(_this.inputList);
        if (this.form.id) {
          await api.productSpecParam.update({ body: { ...this.form } });
          this.isShow = false;
          this.$message.success(
            (this as any).$l.getLanguageText("basic.updateSuccess")
          );
        } else {
          await api.productSpecParam.create({ body: { ...this.form } });
          this.isShow = false;
          this.$message.success(
            (this as any).$l.getLanguageText("basic.createSuccess")
          );
        }
      }
    });
  }

  roleRule = {
    name: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "commoditySpecifications.tips.name"
        ),
        trigger: "blur",
      },
    ],
    nameFr: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "commoditySpecifications.tips.nameFr"
        ),
        trigger: "blur",
      },
    ],
    inputType: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "commoditySpecifications.tips.inputType"
        ),
        trigger: "blur",
      },
    ],
  };
}
