




















































































import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";

import api from "@/api";
import {
  SwiperDto,
  SwiperCreateOrUpdateDto,
  CmsAnchorDto,
  AttachmentHostType,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";

import SimpleUploadImage from "@/components/SimpleUploadImage.vue";

@Component({
  name: "EditSwiper",
  components: { SimpleUploadImage },
})
export default class EditSwiper extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({ required: true })
  dataId!: number;

  defaultData: SwiperCreateOrUpdateDto = {
    redirectUrl: undefined,
    displayName: undefined,
    titleImageUrl: undefined,
    remark: undefined,
    cmsAnchorId: undefined,
    displayOrder: 0,
    id: 0,
  };

  show = false;
  form: SwiperCreateOrUpdateDto = { ...this.defaultData };
  anchorList?: CmsAnchorDto[] = [];
  anchorRecommendSize = "";

  get title() {
    if (this.dataId) {
      return (this as any).$l.getLanguageText("basic.edit") + `${this.form.displayName}`;
    } else {
      return (this as any).$l.getLanguageText("basic.create");
    }
  }

  get hostType() {
    return AttachmentHostType.Swiper;
  }

  @Watch("show")
  async onShowChange(value: boolean) {
    //
    await api.cmsAnchor.getAll({ maxResultCount: 100 }).then((res: any) => {
      this.anchorList = res.items!;
    });

    //

    if (value) {
      const loading = this.$loading({
        target: ".el-dialog",
      });

      if (this.dataId) {
        await api.swiper
          .get({ id: this.dataId })
          .then((res: SwiperDto) => {
            this.form = res!;
            this.loadAnchorSizeRecommend(this.form.cmsAnchorId!);
          })
          .finally(() => {
            setTimeout(() => {
              loading.close();
            }, 200);
          });
      } else {
        this.form = { ...this.defaultData };
        //
        setTimeout(() => {
          loading.close();
        }, 200);
      }
    } else {
      this.form = { ...this.defaultData };
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  loadAnchorSizeRecommend(anchorId: number) {
    if (!anchorId) {
      this.anchorRecommendSize = "";
    } else {
      api.cmsAnchor.get({ id: anchorId }).then((res) => {
        if (res.width && res.height) {
          this.anchorRecommendSize = `建议尺寸：${res.width} x ${res.height}`;
        } else if (res.width) {
          this.anchorRecommendSize = `建议尺寸：宽度${res.width}，高度任意`;
        } else if (res.height) {
          this.anchorRecommendSize = `建议尺寸：宽度任意，高度${res.height}`;
        } else {
          this.anchorRecommendSize = "";
        }
      });
    }
  }

  updateAnchorSizeRecommend() {
    this.loadAnchorSizeRecommend(this.form.cmsAnchorId || 0);
  }

  async save() {
    console.log(this.form);

    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.dataId) {
          await api.swiper.update({
            body: { ...this.form },
          });
        } else {
          await api.swiper.create({
            body: { ...this.form },
          });
        }
        this.show = false;
        this.$message.success((this as any).$l.getLanguageText("basic.updateSuccess").toString());
        this.$emit("onSave");
      }
    });
  }

  cancel() {
    this.show = false;
  }

  linkRule = {
    displayName: [
      {
        required: true,
        message: (this as any).$l.getLanguageText("cmsSwiper.tips.displayName"),
        trigger: "blur",
      },
    ],
    titleImageUrl: [
      {
        required: true,
        message: (this as any).$l.getLanguageText("cmsSwiper.tips.titleImageUrl"),
        trigger: "blur",
      },
    ],
    cmsAnchorId: [
      {
        required: true,
        message: (this as any).$l.getLanguageText("cmsSwiper.tips.cmsAnchorId"),
        trigger: "blur",
      },
    ],
    displayOrder: [
      {
        required: true,
        message: (this as any).$l.getLanguageText("cmsSwiper.tips.displayOrder"),
        trigger: "change",
      },
      {
        type: "number",
        message: (this as any).$l.getLanguageText("cmsSwiper.tips.displayOrderType"),
        trigger: "change",
      },
    ],
  };
}
