









































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import { FeedbackDto } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView.vue";
import AbSelect from "@/components/AbSelect.vue";

@Component({
  name: "FeedbackDtoList",
  components: { PagedTableView, AbSelect },
})
export default class FeedbackDtoList extends Vue {
  queryForm = {
    content: "",
    isReplied: undefined,
  };
  statusList: any = [
    {
      value: true,
      label: "已回复",
    },
    {
      value: false,
      label: "未回复",
    },
  ];
  // 获取表数据
  fetchData(params: any) {
    return api.feedback.getAll(params);
  }
  // 回复
  handleFeedback(row: FeedbackDto) {
    this.$router.push({
      name: "feedbackReply",
      params: { feedbackId: row.id!.toString() },
    });
  }

  // 删除
  async handleDelete(row: FeedbackDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.feedback.delete({ id: row.id }).then((res) => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    });
  }
}
