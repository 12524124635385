































































import api from "@/api";
import { Vue, Component } from "vue-property-decorator";

import { CmsAnchorDto } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView.vue";
import EditLink from "@/views/cms/anchor/edit.vue";

@Component({
  name: "AnchorList",
  components: {
    EditLink,
    PagedTableView,
  },
})
export default class AnchorList extends Vue {
  queryForm = {
    displayName: "",
  };

  editLinkId = 0;

  // 获取表数据
  fetchData(params: any) {
    console.log("test:" + params);
    return api.cmsAnchor.getAll(params);
  }

  // 新建
  handleCreate() {
    this.editLinkId = 0;
    (this.$refs.editForm as any).show = true;
  }

  // 编辑
  handleEdit(index: number, row: CmsAnchorDto) {
    this.editLinkId = row.id!;
    (this.$refs.editForm as any).show = true;
    (this.$refs.editForm as any).form = row;

    console.warn("%cu must reWrite this method", "color:#0048BA;");
  }

  // 删除
  async handleDelete(index: number, row: CmsAnchorDto) {
    this.$confirm(
      (this as any).$l.getLanguageText("basic.disableTip"),
      (this as any).$l.getLanguageText("basic.tip")
    ).then(async () => {
      await api.cmsAnchor
        .delete({
          id: row.id,
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: (this as any).$l.getLanguageText("basic.deleteSuccess"),
          });
        });
    });
  }

  handelOnSaved() {
    this.fetchData(this.queryForm);
  }
}
