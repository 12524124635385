























































































































































































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Watch } from "vue-property-decorator";
import PagedTableView from "@/components/PagedTableView.vue";
import {
  AttachmentHostType,
  ProductBrandCreateOrUpdateDto,
  ProductBrandDto,
} from "@/api/appService";

@Component({
  name: "BrandList",
  components: {
    PagedTableView,
  },
})
export default class BrandList extends Vue {
  queryForm = {
    name: "",
  };

  id = 0;
  isCreate = true;
  isShow = false;
  showDelete = false;
  imageUrl = "";
  form: ProductBrandCreateOrUpdateDto = {
    id: 0,
    name: undefined,
    nameFr: undefined,
    description: "",
    imagePath: "",
    displayOrder: 0,
  };
  public onProgress = false;
  public uploadPercent = 0;

  @Watch("imageUrl")
  onValueChange(newVal: string | number) {
    if (newVal !== "") {
      this.showDelete = true;
    } else {
      this.showDelete = false;
    }
  }

  // 获取表数据
  fetchData(params: any) {
    console.log("test:" + params);
    return api.productBrand.getAll(params);
  }

  beforeUpload(file: any) {
    console.log("beforeUpload:", file);
    const isJPG =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/gif";
    const isLt1M = file.size / 1024 / 1024 < 1;

    if (!isJPG) {
      this.$message.error("上传图片只能是 png，jpeg，jpg，gif 格式!");
    }
    if (!isLt1M) {
      this.$message.error("上传图片大小不能超过 1MB!");
    }
    return isJPG && isLt1M;
  }

  public get uploadData(): any {
    return { hostType: AttachmentHostType.ProductBrand };
  }

  public handleProgress(e: any): void {
    this.onProgress = true;
    this.uploadPercent = e.percent;
    console.log("handleProgress", e);
  }

  get headers() {
    return {
      "Abp-OrganizationUnitId": (this as any).$store.getters["user/ouId"] || "",
      "Abp-TenantId": (this as any).$store.getters["app/tenantId"],
      Authorization: `Bearer ${(this as any).$store.getters["user/token"]}`,
      ".AspNetCore.Culture": "c=zh-Hans|uic=zh-Hans",
    };
  }

  handlePictureCardPreview(file: any) {
    this.imageUrl = file.url;
  }

  handleRemove(file: any, fileList: any) {
    console.log(file, fileList);
    this.imageUrl = "";
  }

  handleSuccess(res: any, file: any) {
    this.onProgress = false;
    this.uploadPercent = 100;
    this.imageUrl = res.result.url as string;
    // this.form.imagePath = res.result.url
  }

  // 新建
  handleCreate() {
    // this.$router.push({
    //   name: "BrandCreate"
    // });
    this.form = {} as ProductBrandCreateOrUpdateDto;
    this.imageUrl = "";
    this.isCreate = true;
    this.isShow = true;
  }

  // 编辑
  handleEdit(index: number, row: ProductBrandDto) {
    this.id = row.id!;
    // this.$router.push({
    //   name: "BrandEdit",
    //   params: { id: row.id! + "" },
    // });
    this.isCreate = false;
    api.productBrand.get({ id: this.id }).then((res: any) => {
      if (res) {
        this.form = { ...res };
        this.imageUrl = this.form.imagePath!;
      }
    });
    this.isShow = true;
  }

  // 删除
  async handleDelete(index: number, row: ProductBrandDto) {
    this.$confirm(
      (this as any).$l.getLanguageText("basic.disableTip"),
      (this as any).$l.getLanguageText("basic.tip")
    ).then(async () => {
      await api.productBrand
        .delete({
          id: row.id,
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: (this as any).$l.getLanguageText("basic.deleteSuccess"),
          });
        });
    });
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        this.form.imagePath = this.imageUrl;
        if (this.form.id) {
          if (!this.form.displayOrder) {
            this.form.displayOrder = 0;
          }
          await api.productBrand.update({ body: { ...this.form } });
          this.isShow = false;
          this.$message.success(
            (this as any).$l.getLanguageText("basic.updateSuccess")
          );
        } else {
          await api.productBrand.create({ body: { ...this.form } });
          this.isShow = false;
          this.$message.success(
            (this as any).$l.getLanguageText("basic.createSuccess")
          );
        }
      }
    });
  }

  roleRule = {
    name: [
      {
        required: true,
        message: (this as any).$l.getLanguageText("commodityBrand.tips.name"),
        trigger: "blur",
      },
    ],
    nameFr: [
      {
        required: true,
        message: (this as any).$l.getLanguageText("commodityBrand.tips.nameFr"),
        trigger: "blur",
      },
    ],
  };
}
